import { BackArrow } from 'assets';
import { FormSubmit, ValidatedInput } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  calculateSingleRoof,
  GableRoof,
  RoofCalcResult,
  RoofCalculatorResult,
  RoofInputs,
} from 'modules/calculator';
import React, { Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { calculatorStyles } from 'styles';

const GableRoofCalculator: React.FC = () => {
  const { formatMessage } = useIntl();
  const [data, setData] = useState<GableRoof | null>(null);
  const [calcResult, setCalcResult] = useState<RoofCalcResult | null>(null);

  const methods = useForm<GableRoof>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const roof = graphql`
    query {
      file(relativePath: { eq: "calculator/dvostresni_krov.jpg" }) {
        id
        childImageSharp {
          fluid(quality: 100, maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `;

  const roofPicture = useStaticQuery(roof).file;

  return (
    <div css={calculatorStyles.calculatorConatiner}>
      <div css={calculatorStyles.titleCalculator}>
        {calcResult && (
          <div onClick={onBackClick} css={calculatorStyles.backWrapper}>
            <BackArrow />
          </div>
        )}
        <h2 css={calculatorStyles.title}>
          <FormattedMessage id="calculator.double-sided.title" />
        </h2>
      </div>
      {calcResult ? (
        <RoofCalculatorResult data={calcResult} />
      ) : (
        <Fragment>
          <GatsbyImage
            {...roofPicture.childImageSharp}
            alt={formatMessage({ id: 'calculator.double-sided' })}
            css={calculatorStyles.image}
          />

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div css={calculatorStyles.calculatorWrapper}>
                <ValidatedInput
                  name="roofLength"
                  label={formatMessage(
                    { id: 'calculator.roof-length' },
                    { lengthOf: 'krovišta' },
                  )}
                  defaultValue={data ? data.roofLength : undefined}
                  size="small"
                  placeholder={formatMessage(
                    { id: 'calculator.roof-length' },
                    { lengthOf: 'krovišta' },
                  )}
                  validationObject={{
                    required: formatMessage({
                      id: 'shared.validation.required',
                    }),
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: formatMessage({
                        id: 'shared.validation.decimal-number',
                      }),
                    },
                  }}
                />

                <ValidatedInput
                  name="raftersLength"
                  label={formatMessage(
                    { id: 'calculator.roof-length' },
                    { lengthOf: 'rogova' },
                  )}
                  size="small"
                  defaultValue={data ? data.raftersLength : undefined}
                  placeholder={formatMessage(
                    { id: 'calculator.roof-length' },
                    { lengthOf: 'rogova' },
                  )}
                  validationObject={{
                    required: formatMessage({
                      id: 'shared.validation.required',
                    }),
                    pattern: {
                      value: /^\d+(\.\d+)?$/,
                      message: formatMessage({
                        id: 'shared.validation.decimal-number',
                      }),
                    },
                  }}
                />

                <RoofInputs data={data} />
                <div />
              </div>

              <div>
                <FormSubmit text={formatMessage({ id: 'shared.calculate' })} />
              </div>
            </form>
          </FormProvider>
        </Fragment>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </div>
  );

  function onSubmit(data: GableRoof) {
    setData(data);
    setCalcResult(calculateSingleRoof(data));
  }

  function onBackClick() {
    setCalcResult(null);
  }
};

export default GableRoofCalculator;
